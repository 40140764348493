import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/Layout/Layout";
import SEO from "../components/seo";
import PageHero from "../components/PageSections/PageHero";
import CopyAndButton from "../components/PageSections/CopyAndButton";
import Contact from "../components/PageSections/ContactUs";
import GetInvolved from "../components/PageSections/GetInvolved";
import TwoPanels from "../components/PageSections/TwoPanels";
// import Line from "../components/PageComponents/Line";

const ForFamiliesPage = () => {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "for-families" } }) {
        nodes {
          title
          slug
          acf {
            hero {
              art {
                source_url
              }
              artist {
                age
                credit
              }
              title
              copy
              cta_type
              cta_text
              cta_link
            }
            family_bridge_toolkit {
              title
              content
              download_link {
                title
                file {
                  url {
                    source_url
                  }
                }
              }
            }
            two_column {
              left_column
              content_right_column
            }
            contact
          }
          yoast_meta {
            yoast_wpseo_social_defaults {
              og_frontpage_image {
                source_url
              }
            }
            yoast_wpseo_metadesc
            yoast_wpseo_facebook_title
            yoast_wpseo_facebook_image {
              source_url
            }
            yoast_wpseo_facebook_description
            yoast_wpseo_twitter_title
            yoast_wpseo_twitter_image {
              source_url
            }
            yoast_wpseo_twitter_description
          }
        }
      }
    }
  `);
  const forFamiliesData = data?.allWordpressPage?.nodes[0];
  const { yoast_meta, title } = data.allWordpressPage.nodes[0];

  const {
    yoast_wpseo_metadesc: seo_desc,
    yoast_wpseo_facebook_title: seo_fb_title,
    yoast_wpseo_facebook_description: seo_fb_desc,
    yoast_wpseo_twitter_title: seo_tw_title,
    yoast_wpseo_twitter_description: seo_tw_desc
  } = yoast_meta;

  const seo_image =
    yoast_meta.yoast_wpseo_social_defaults?.og_frontpage_image?.source_url ||
    "";
  const seo_fb_image =
    yoast_meta.yoast_wpseo_facebook_image?.source_url || seo_image;
  const seo_tw_image =
    yoast_meta.yoast_wpseo_twitter_image?.source_url || seo_image;

  const {
    hero,
    family_bridge_toolkit,
    two_column,
    contact
  } = forFamiliesData?.acf;

  return (
    <Layout>
      <SEO
        title={title}
        description={seo_desc}
        image={seo_fb_image}
        metaTitleFacebook={seo_fb_title}
        metaImageFacebook={seo_fb_image}
        metaDescFacebook={seo_fb_desc}
        metaTitleTwitter={seo_tw_title}
        metaImageTwitter={seo_tw_image}
        metaDescTwitter={seo_tw_desc}
      />
      <PageHero {...hero} />
      <CopyAndButton
        title={family_bridge_toolkit?.title}
        content={family_bridge_toolkit?.content}
        button={family_bridge_toolkit?.download_link}
      />
      <TwoPanels
        list={false}
        left={two_column?.left_column}
        right={two_column?.content_right_column}
      />
      <Contact content={contact} />
      <GetInvolved />
    </Layout>
  );
};

export default ForFamiliesPage;
